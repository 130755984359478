(function() {
    'use strict';

    angular
        .module('specialFunction')
        .controller('SpecialFunctionController', Controller);

    Controller.$inject = ['GridDataSource', 'ServerGateway', '$q', 'config', '$timeout', '$scope', 'PageViewModel'];

    function Controller(GridDataSource, ServerGateway, $q, config, $timeout, $scope, PageViewModel) {
        var serverGateway = null;
        var vm = this;
        
        vm.updateHongKongPostShipments = updateHongKongPostShipments;
        vm.moveTransitionQueue = moveTransitionQueue;
        vm.allocateTransitionQueue = allocateTransitionQueue;
        vm.allocateTransitionQueueBatch = allocateTransitionQueueBatch;
        vm.allocateWaitingBatch = allocateWaitingBatch;
        vm.allocateComplexBatch = allocateComplexBatch;
        vm.showAllocateTransitionQueue = showAllocateTransitionQueue;
        vm.queueTypeList = config.buckets;
        vm.lookupHKPostOrderNo = lookupHKPostOrderNo;
        vm.handlePrintableIssue = handlePrintableIssue;
        vm.updatePrintableIssue = updatePrintableIssue;
        vm.sendShippingReport = sendShippingReport;
        vm.closeoutDHLShipment = closeoutDHLShipment;
        vm.lookupCode = lookupCode;
        vm.recordScan = recordScan;
        vm.recordWatchAssemblyScan = recordWatchAssemblyScan;
        vm.fixOrderBucket = fixOrderBucket;
        vm.lookupCodeHistory = {};
        vm.prepareGiftOrder = prepareGiftOrder;
        vm.showOOSPendingOrder = showOOSPendingOrder;
        vm.showOOSHoldOrder = showOOSHoldOrder;
        vm.showPendingOrder = showPendingOrder;
        vm.showIdleOrder = showIdleOrder;
        vm.showConvertOrder = showConvertOrder;
        vm.restockReschedulePrinting = restockReschedulePrinting;
        vm.convertOrders = null;
        vm.convertOrderIdFormat = convertOrderIdFormat;
        vm.outOfStockPrintingSpecialHandling = outOfStockPrintingSpecialHandling;
        vm.removeInvalidPrintingItems = removeInvalidPrintingItems;
        vm.sendInventoryReport = sendInventoryReport;
        vm.sendBOMReport = sendBOMReport;
        vm.scanHistories = [];
        vm.saveLocation = false;
        vm.downloadNonPrintBatch = downloadNonPrintBatch;
        vm.showYamatoPickup = showYamatoPickup;
        vm.slotsData = config.slotsData;
        vm.manualPrintResult = '';
        vm.generateManualPrint = generateManualPrint;
        vm.stationId = config.stationId;
        vm.templateId = config.templateId;
        vm.updateStockControlRemark = updateStockControlRemark;
        vm.schedulePendingOrderIds = null;
        vm.schedulePendingOrder = schedulePendingOrder;
        vm.createDemoOrders = createDemoOrders;
        vm.deleteDemoOrders = deleteDemoOrders;
        vm.updateSZPrintingQuota = updateSZPrintingQuota;
        vm.showWatchAssemblyEdit = showWatchAssemblyEdit;
        vm.rejectWatchband = rejectWatchband;
        vm.scrapWatchband = scrapWatchband;
        vm.demoAssignPrintBotTask = demoAssignPrintBotTask;
        vm.demoPrintBotTaskStartPrinting = demoPrintBotTaskStartPrinting;
        vm.demoPrintBotTaskFinishPrinting= demoPrintBotTaskFinishPrinting;
        vm.demoPrintBotTaskComplete = demoPrintBotTaskComplete;
        vm.demoListPrintBotTask = demoListPrintBotTask;
        vm.exportBacklog = exportBacklog;
        vm.saveRawMaterialZone = saveRawMaterialZone;
        vm.scheduleNonPrintTask = scheduleNonPrintTask;
        vm.clearShippingLabel = clearShippingLabel;
        vm.prefillScanLocation = prefillScanLocation;
        vm.scheduleSZPrinting = scheduleSZPrinting;
        vm.packStationTestPrint = packStationTestPrint;
        vm.scheduleManaulOrder = scheduleManaulOrder;
        vm.cleanupManaulOrder = cleanupManaulOrder;
        vm.allocateInternalTransitQueueBatch = allocateInternalTransitQueueBatch;
        vm.goOverview = goOverview;
        vm.showMergeSlotCreateDialog = showMergeSlotCreateDialog;
        vm.createMergeGroup = createMergeGroup;
        vm.saveMinPrintingBatchItemForHK = saveMinPrintingBatchItemForHK;
        vm.saveMinCurrentPrintingBatchItemForHK = saveMinCurrentPrintingBatchItemForHK;
        vm.openSpecialHandlingArtworkModal = openSpecialHandlingArtworkModal;
        vm.specialHandlingArtworkList = [];
        vm.specialHandlingArtwork = '';
        vm.addSpecialHandlingArtwork = addSpecialHandlingArtwork;
        vm.deleteSpecialHandlingArtwork = deleteSpecialHandlingArtwork;
        vm.regeneratePrintables = regeneratePrintables;
        vm.sendFullWarehouseReport = sendFullWarehouseReport;
        vm.sendPresetWarehouseReport = sendPresetWarehouseReport;
        vm.loadBalanceStations = "";
        vm.applyLoadBalance = applyLoadBalance;
        vm.serialNumberCount = 0;
        vm.serialNumberBatchDate = null;
        vm.generateSerialNumber = generateSerialNumber;
        vm.downloadSerialNumber = downloadSerialNumber;
        vm.popRequestDate = null;
        vm.popRequestOrderId = null;
        vm.downloadPop = downloadPop;
        vm.generatePopFileLoading = false;
        vm.requestInventoryForecastReport = requestInventoryForecastReport;
        vm.inventoryForecastRequested = false;
        vm.sendPresetWarehouseReportLoading = false;
        vm.persistentVarMap = [];
        vm.setPersistenceVar = setPersistenceVar;
        vm.submitBulkSwitchOrderLocation = submitBulkSwitchOrderLocation;
        vm.bulkSwitchLocationId = 'default';
        vm.bulkSwitchOrderIds = '';
        vm.switchOrderLocationLoading = false;
        vm.bypassOrderList = JSON.stringify(config.bypassOrderList,null,2);
        vm.saveBypassOrderList = saveBypassOrderList;
        vm.resetRoboticAllTote = resetRoboticAllTote;
        vm.resetRoboticAllToteLoading = false;
        vm.showCreateModal = showCreateModal;
        vm.showSpecialHandlingArtwork = showSpecialHandlingArtwork;
        vm.createSpecialHandlingArtwork = createSpecialHandlingArtwork;
        vm.updateSpecialHandlingArtwork = updateSpecialHandlingArtwork;
        vm.removeSpecialHandlingArtwork = removeSpecialHandlingArtwork;
        vm.submitScannerInput = submitScannerInput;
        vm.migrateBulkOrder = migrateBulkOrder;
        vm.bulkOrderHidePrinting = bulkOrderHidePrinting;
        vm.bulkOrderMarkShipped = bulkOrderMarkShipped;
        vm.migrateBulkOrderId = null;
        vm.scanner = {}
        vm.scanner.mode = config.defaultMode ? config.defaultMode : null;
        vm.scanner.input = null;

        vm.fromSku = null;
        vm.toSku = null;

        vm.searchUnshupProductionItemSku = searchUnshupProductionItemSku;
        vm.changeUnshupProductionItemSku = changeUnshupProductionItemSku;


        if (typeof config.minPrintingBatchItemForHK != 'underfined') {
            vm.minPrintingBatchItemForHK = config.minPrintingBatchItemForHK;
        }
        if(typeof config.minCurrentPrintingBatchItemForHK != 'underfined'){
            vm.minCurrentPrintingBatchItemForHK = config.minCurrentPrintingBatchItemForHK;
        }
        if (config.persistentVarMap) {
            vm.persistentVarMap = config.persistentVarMap;
        }

        initialize();

        function initialize() {
            // sever side gateway setup
            serverGateway = new ServerGateway({
                endPoints: {
                    'moveTransitionQueue': { path: 'function/moveTransitionQueue', method: 'POST' },
                    'allocateTransitionQueue': { path: 'transition-queue/allocate', method: 'POST' },
                    'allocateTransitionQueueBatch': { path: 'transition-queue/batch/allocate', method: 'POST' },
                    'allocateWaitingBatch': { path: 'transition-queue/waiting-batch/allocate', method: 'POST' },
                    'allocateComplexBatch': { path: 'transition-queue/complex-batch/allocate', method: 'POST' },
                    'lookupHKPostOrderNo': { path: 'shipments/lookupHKPostOrderNo', method: 'GET' },
                    'handlePrintableIssue': { path: 'function/printable-issues/{id}/handle', method: 'POST' },
                    'updatePrintableIssue': { path: 'function/printable-issues/{id}/update', method: 'POST' },
                    'sendShippingReport': { path: 'function/send-usps-report', method: 'GET' },
                    'lookupCode': { path: 'function/lookup-code-result', method: 'GET' },
                    'lookupTrackingCode': { path: 'function/lookup-tracking-code-result', method: 'GET' },
                    'recordScan': { path: 'function/record-transition-queue-scan', method: 'POST' },
                    'recordWatchAssemblyScan': { path: 'function/record-watch-assembly', method: 'POST' },
                    'fixOrderBucket': { path: 'function/fix-production-item-bucket', method: 'POST' },
                    'closeoutDHLShipment': { path: 'function/closeout-dhl-shipments', method: 'POST' },
                    'prepareGiftOrder': { path: 'function/prepare-gift-order', method: 'POST' },
                    'getOOSPendingOrder': { path: 'function/get-oos-pending-order', method: 'GET' },
                    'getOOSHoldOrder': { path: 'function/get-oos-hold-order', method: 'GET' },
                    'getPendingOrder': { path: 'function/get-pending-order', method: 'GET' },
                    'getIdleOrder': { path: 'function/get-idle-order', method: 'GET' },
                    'restockReschedulePrinting': { path: 'function/restock-schedule-printing', method: 'POST' },
                    'outOfStockPrintingSpecialHandling': { path: 'function/out-of-stock-printing-special-handling', method: 'POST' },
                    'removeInvalidPrintingItems': { path: 'function/remove-invalid-printing-items', method: 'POST' },
                    'sendInventoryReport': { path: 'function/send-inventory-report', method: 'POST' },
                    'sendFullWarehouseReport': { path: 'function/send-full-warehouse-report', method: 'POST' },
                    'sendPresetWarehouseReport': { path: 'function/send-preset-warehouse-report', method: 'POST' },
                    'sendBOMReport': { path: 'function/send-bom-report', method: 'POST' },
                    'generateYamatoPickupForm': { path: 'function/yamato-pickup-form', method: 'GET' },
                    'generateManualPrint': { path: 'function/generate-manual-print', method: 'POST' },
                    'updateStockControlRemark': { path: 'products/{id}/updateStockControlRemark', method: 'POST' },
                    'schedulePendingOrder': { path: 'function/schedulePendingOrder', method: 'POST' },
                    'createDemoOrders': { path: 'function/create-demo-orders', method: 'POST' },
                    'cancelDemoOrders': { path: 'function/cancel-demo-orders', method: 'POST' },
                    'updateSZPrintingQuota': { path: 'function/update-sz-printing-quota', method: 'POST' },
                    'listTransitionQueueItems': { path: 'transition-queue/list-items', method: 'GET' },
                    'rejectWatchband': { path: 'transition-queue/{id}/reject-watchband', method: 'POST' },
                    'scrapWatchband': { path: 'transition-queue/{id}/scrap-watchband', method: 'POST' },
                    'demoAssignPrintBotTask': { path: 'print-bots/{id}/createTask', method: 'POST' },
                    'demoPrintBotTaskStartPrinting': { path: 'print-bot-tasks/{id}/print-start', method: 'POST' },
                    'demoPrintBotTaskFinishPrinting': { path: 'print-bot-tasks/{id}/print-finish', method: 'POST' },
                    'demoPrintBotTaskComplete': { path: 'print-bot-tasks/{id}/complete', method: 'POST' },
                    'demoListPrintBotTask': { path: 'print-bots/{id}/tasks', method: 'GET' },
                    'updateRawMaterialWIPZone': { path: 'raw-materials/{id}/udpateWIPZone', method: 'POST' },
                    'scheduleNonPrintTask': { path: 'function/schedule-non-print-task', method: 'POST' },
                    'cancelOrder': { path: 'orders/{id}/cancel', method: 'POST' },
                    'clearShippingLabel': { path: 'function/clear-shipping-label-cache', method: 'POST' },
                    'scheduleSZPrinting': { path: 'function/schdule-sz-printing', method: 'POST' },
                    'packStationTestPrint': { path: 'function/pack-stations/{id}/test-print', method: 'POST' },
                    'scheduleManaulOrder': { path: 'function/schedule-manual-order', method: 'POST' },
                    'cleanupManaulOrder': { path: 'function/cleanup-manual-order', method: 'POST' },
                    'createMergeGroup': { path: 'merge-slots/create', method: 'POST' },
                    'saveMinPrintingBatchItemForHK': { path: 'function/save-min-printing-batch-item-for-hk', method: 'POST' },
                    'saveMinCurrentPrintingBatchItemForHK': { path: 'function/save-min-current-printing-batch-item-for-hk', method: 'POST' },
                    'getSpecialHandlingArtworkList': {path: 'function/getSpecialHandlingArtworkList', method: 'GET'},
                    'addSpecialHandlingArtworkList': {path: 'function/addSpecialHandlingArtworkList', method: 'POST'},
                    'deleteSpecialHandlingArtworkList': {path: 'function/deleteSpecialHandlingArtworkList/{id}', method:'DELETE'},
                    'regeneratePrintables': {path: 'function/regeneratePrintables/{id}', method:'POST'},
                    'applyLoadBalance': { path: 'function/applyLoadBalance', method: 'POST' },
                    'generateSerialNumber': {path: 'function/generate-serial-number', method: 'GET'},
                    'downloadPop': {path: 'function/downloadPop', method: 'GET'},
                    'saveBypassOrderList': {path: 'function/saveBypassOrderList', method: 'GET'},
                    'requestInventoryForecastReport': {path: 'function/send-inventory-forecast', method: 'POST'},
                    'setPersistenceVar': {path: 'function/save-persistence-var', method: 'POST'},
                    'submitBulkSwitchOrderLocation': {path: 'function/submit-bulk-switch-location', method: 'POST'},
                    'forceRequestPrintable': { path: 'printables/{id}/refetch',  method: 'POST' },
                    'forceRequestPrintablePreview': { path: 'printables/{id}/refetchPreview',  method: 'POST' },
                    'resetRoboticAllTote': { path: 'robotic/reset-all-tote',  method: 'POST' },
                    'showSpecialHandlingArtwork': { path: 'function/special-handling-artwork/{id}', method: 'GET' },
                    'createSpecialHandlingArtwork': { path: 'function/special-handling-artwork/create', method: 'POST' },
                    'updateSpecialHandlingArtwork': { path: 'function/special-handling-artwork/{id}}', method: 'POST' },
                    'removeSpecialHandlingArtwork': { path: 'function/special-handling-artwork/{id}}', method: 'DELETE' },
                    'fulfill-transit-request': { path: 'inventory/transit-requests/{id}/fulfill', method: 'POST' },
                    'searchUnshupProductionItemSku': { path: 'production-items/change-unship-item-sku', method: 'POST' },
                    'changeUnshupProductionItemSku': { path: 'production-items/change-unship-item-sku', method: 'POST' },
                    'migrateBulkOrder': { path: 'function/migrate-bulk-order', method: 'POST' },
                    'bulkOrderHidePrinting': { path: 'function/bulk-order-hide-printing', method: 'POST' },
                    'bulkOrderMarkShipped': { path: 'orders/{id}/bulk-order-mark-shipped', method: 'POST' },
                    'getBackgroundOutput': {path: 'background-outputs/{id}', method: 'GET'},
                }
            });
            setInterval(function () {
                if (vm.lockFocus) {
                    $("#scanCode").focus();
                }
            }, 500);
            vm.scanType = 'buckle_n_adaptor';

            $('#modal-show,#modal-create').on('hide.bs.modal', function () {
                window.history.pushState(
                  null,
                  null,
                  '/function/special-handling-artwork'
                );
              });

            initStationSelect2();
        }

        function updateHongKongPostShipments() {
            if ($('#updateHongKongPostShipmentsBtn').hasClass('disabled')) {
                return;
            }
            
            $.ajax({
                url: '/function/other/update-hongkongpost-shipments',
                type: 'post',
                dataType: 'json',
                success: function (res) {
                    $.bootstrapGrowl('Hong Kong Post Shipment are now updating. You will receive an email when it completes.', {ele: 'body', type: 'success'});
                    $('#updateHongKongPostShipmentsBtn').addClass('disabled').text('Updating');
                    $('#updateHongKongPostShipmentsTime').text('Just now');
                    
                    console.log(res);
                },
                error: function (xhr) {
                    try {
                        var res = $.parseJSON(xhr.responseText);
                        if (res.hasOwnProperty('error')) {
                            $.bootstrapGrowl(res.error, {ele: 'body', type: 'error'});
                        }
                    } catch (e) { }
                    console.log(xhr);
                }
            });
        }

        function moveTransitionQueue() {
            serverGateway.ajax('moveTransitionQueue').then(function(response) {
                $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function allocateTransitionQueue() {
            $('#allocateTransitionQueueModal').modal('hide');
            serverGateway.ajax('allocateTransitionQueue', null, {'config': JSON.stringify(vm.queueTypeList.map((item) => { item.location_id = 4; if (item.queueType == "Universal" || item.queueType == "Universal | SZ-HK") item.location_id = 11; return item }))}).then(function(response) {
                // $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
                if (response.data.filename) {
                    window.location.href="/transition-queue/export?filename=" + response.data.filename;
                } else {
                    window.location.href="/transition-queue/export";
                }
            }, function (response) {
                $('#allocateTransitionQueueModal').modal('show');
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function allocateTransitionQueueBatch() {
            var queueType = prompt("Queue Type?", 'Universal');
            var batchItemQty = prompt("item per batch?", 12);
            var quantity = prompt("Number of batch?");
            
            var data = {
                'batch' : [],
                'quantity' : quantity,
                'location_id' : 11,
            };
            if (!batchItemQty) {
                return;
            }
            for (var i = 0; i < batchItemQty; i++) {
                data.batch.push({'queueType':queueType,'sortSeq':(i + 1)});
            }
            serverGateway.ajax('allocateTransitionQueueBatch', null, {'config': JSON.stringify(data)}).then(function(response) {
                // $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
                if (response.data.filename) {
                    window.location.href="/transition-queue/export?filename=" + response.data.filename;
                } else {
                    window.location.href="/transition-queue/export";
                }
            }, function (response) {
                $('#allocateTransitionQueueModal').modal('show');
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function allocateInternalTransitQueueBatch() {
            var queueType = 'Universal | SZ-HK';
            var batchItemQty = prompt("item per batch?", 12);
            var quantity = prompt("Number of batch?");
            
            var data = {
                'batch' : [],
                'quantity' : quantity,
                'location_id' : 11,
            };
            if (!batchItemQty) {
                return;
            }
            for (var i = 0; i < batchItemQty; i++) {
                data.batch.push({'queueType':queueType,'sortSeq':(i + 1)});
            }
            serverGateway.ajax('allocateTransitionQueueBatch', null, {'config': JSON.stringify(data)}).then(function(response) {
                // $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
                if (response.data.filename) {
                    window.location.href="/transition-queue/export?filename=" + response.data.filename;
                } else {
                    window.location.href="/transition-queue/export";
                }
            }, function (response) {
                $('#allocateTransitionQueueModal').modal('show');
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }


        function allocateWaitingBatch() {
            var quantity = prompt("Number of batch?");
            var batchItemQty = prompt("item per batch?", 12);
            var queueType = prompt("Queue Type?", 'Simple | Large');
            var data = {
                'batch' : [],
                'quantity' : quantity,
                'location_id' : 11,
            };
            if (!batchItemQty) {
                return;
            }
            for (var i = 0; i < batchItemQty; i++) {
                data.batch.push({'queueType':queueType,'sortSeq':(i + 1)});
            }
            serverGateway.ajax('allocateWaitingBatch', null, {'config': JSON.stringify(data)}).then(function(response) {
                // $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
                if (response.data.filename) {
                    window.location.href="/transition-queue/export?filename=" + response.data.filename;
                } else {
                    window.location.href="/transition-queue/export";
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function allocateComplexBatch() {
            var quantity = prompt("Number of batch?");
            var batchItemQty = prompt("item per batch?", 12);
            var queueType = prompt("Queue Type?", 'Complex');
            var data = {
                'batch' : [],
                'quantity' : quantity,
                'location_id' : 11,
            };
            if (!batchItemQty) {
                return;
            }
            for (var i = 0; i < batchItemQty; i++) {
                data.batch.push({'queueType':'Complex','sortSeq':(i + 1)});
            }
            serverGateway.ajax('allocateComplexBatch', null, {'config': JSON.stringify(data)}).then(function(response) {
                // $.bootstrapGrowl('Moved ' + response.data.count + ' items' , {ele: 'body', type: 'success'});
                if (response.data.filename) {
                    window.location.href="/transition-queue/export?filename=" + response.data.filename;
                } else {
                    window.location.href="/transition-queue/export";
                }
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showAllocateTransitionQueue() {
            $('#allocateTransitionQueueModal').modal('show');
        }

        function lookupHKPostOrderNo() {
            serverGateway.ajax('lookupHKPostOrderNo', null, {'orderNo': vm.hkpostOrderNo}).then(function(response) {
                vm.HKPostOrderIds = response.data.orderIds.join();

            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function handlePrintableIssue(printableIssueId) {
            vm.printableIssueLoading = true;
            serverGateway.ajax('handlePrintableIssue', {id: printableIssueId}).then(function(response) {
                vm.printableIssueLoading = false;
                window.location.reload();
            }, function (response) {
                vm.printableIssueLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updatePrintableIssue(printableIssueId) {
            vm.printableIssueLoading = true;
            serverGateway.ajax('updatePrintableIssue', {id: printableIssueId}).then(function(response) {
                vm.printableIssueLoading = false;
                window.location.reload();
            }, function (response) {
                vm.printableIssueLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sendShippingReport() {
            $.ajax({
                url: '/function/send-usps-report',
                type: 'get',
                success: function (res) {
                    window.location.reload();
                },
                error: function (xhr) {
                    $.bootstrapGrowl('Failed', {ele: 'body', type: 'error'});
                }
            });
        }

        function lookupCode() {
            vm.lookupCodeLoading = true;
            serverGateway.ajax('lookupCode', null, {code: vm.code}).then(function(response) {
                vm.lookupCodeLoading = false;
                vm.lookupCodeResult = {};
                vm.lookupTrackingCodeResult = {};
                vm.lookupCodeResultLinks = [];
                var currentQueue = null;
                console.log(response.data);
                if (!response.data || !response.data.data || !response.data.data.length) {
                    vm.lookupCodeLoading = true;
                    serverGateway.ajax('lookupTrackingCode', null, {code: vm.code}).then(function(response) {
                        vm.lookupCodeLoading = false;
                        // vm.lookupTrackingCodeResult = response.data.data;
                        var order = response.data.data;
                        for (index in order.deliveries) {
                            var delivery = order.deliveries[index];
                            if (!delivery.orderItems) {
                                continue;
                            }
                            for (index2 in delivery.orderItems) {
                                var orderItem = delivery.orderItems[index2];
                                if (!orderItem.productionItems) {
                                    continue;
                                }
                                for (index3 in orderItem.productionItems) {
                                    var productionItem = orderItem.productionItems[index3];
                                    if (productionItem.transitionQueueItem) {
                                        var transitionQueueId = productionItem.transitionQueueItem.transitionQueue.id;
                                        console.log(transitionQueueId);
                                        for (index4 in vm.lookupCodeHistory) {
                                            if (vm.lookupCodeHistory[index4] == transitionQueueId) {
                                                vm.lookupTrackingCodeResult[transitionQueueId] = index4;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                } else {
                    for (var index in response.data.data) {
                        currentQueue = response.data.data[index].transitionQueue.id;
                        if (!response.data.data[index].productionItem.orderItem.delivery.readyForPack) {
                            continue;
                        }
                        var allFound = true;
                        var relatedItems = response.data.data[index].productionItem.orderItem.delivery.orderItems;
                        var traceHistory = {};
                        for (var index2 in relatedItems) {
                            var relatedProductionItems = relatedItems[index2].productionItems;
                            if (relatedProductionItems) {
                                for (var index3 in relatedProductionItems) {
                                    if (relatedProductionItems[index3].transitionQueueItem && relatedProductionItems[index3].transitionQueueItem.transitionQueue) {
                                        if (relatedProductionItems[index3].transitionQueueItem.transitionQueue.id == currentQueue) {
                                            traceHistory[relatedProductionItems[index3].transitionQueueItem.transitionQueue.id] = 'Current';
                                            continue;
                                        }
                                        var found = false;
                                        for (var index4 in vm.lookupCodeHistory) {
                                            if (vm.lookupCodeHistory[index4] == relatedProductionItems[index3].transitionQueueItem.transitionQueue.id) {
                                                found = true;
                                                traceHistory[relatedProductionItems[index3].transitionQueueItem.transitionQueue.id] = index4;
                                                break;
                                            }
                                        }
                                        if (!found) {
                                            allFound = false;
                                            
                                            break;
                                        }
                                    }
                                }
                            }
                            if (!allFound) {
                                break;
                            }
                        }
                        if (allFound) {
                            if (response.data.data[index].productionItem.orderItem.delivery.order.refId) {
                                vm.lookupCodeResult[response.data.data[index].productionItem.orderItem.delivery.order.refId] = traceHistory;
                                if ($.inArray('http://admin.casetify.com/tracking/order/' + response.data.data[index].productionItem.orderItem.delivery.order.refId, vm.lookupCodeResultLinks) == -1) {
                                    vm.lookupCodeResultLinks.push('http://admin.casetify.com/tracking/order/' + response.data.data[index].productionItem.orderItem.delivery.order.refId);
                                }
                                
                            }
                            
                        }
                    }
                    if (currentQueue) {
                        vm.lookupCodeHistory[Object.keys(vm.lookupCodeHistory).length+1] = currentQueue;
                        console.log(JSON.stringify(vm.lookupCodeHistory));
                    }
                }
                
                // vm.lookupCodeResult = currentQueue;
                // vm.lookupCodeHistory = response.data.data;
                vm.code = '';
                $("input#code").focus();
            }, function (response) {
                vm.lookupCodeLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function recordScan() {
            if (!$("input#scanCode").val() || $("input#scanCode").val() == '') {
                $("input#scanCode").focus();
                return;
            }
            if (!$("input#scanLocation").val() || $("input#scanLocation").val() == '') {
                $("input#scanLocation").focus();
                return;
            }
            vm.recordScanLoading = true;
            serverGateway.ajax('recordScan', null, {location: vm.scanLocation, code: vm.scanCode}).then(function(response) {
                vm.recordScanLoading = false;
                if (response.data.success) {
                    vm.scanHistories.unshift({
                        scanCode: $("input#scanCode").val(),
                        location: $("input#scanLocation").val(),
                        status: 'Success'
                    });
                    $.bootstrapGrowl($("input#scanCode").val() + "->" + $("input#scanLocation").val() + " Success", {ele: 'body', type: 'success'});
                    $("input#scanCode").val('');
                    if (vm.saveLocation) {
                        $("input#scanCode").focus();
                    } else {
                        $("input#scanLocation").val('');
                        vm.scanLocation = null;
                        $("input#scanLocation").focus();
                    }

                } else {
                    $.bootstrapGrowl($("input#scanCode").val() + "->" + $("input#scanLocation").val() + " Error", {ele: 'body', type: 'error'});
                    vm.scanHistories.unshift({
                        scanCode: $("input#scanCode").val(),
                        location: $("input#scanLocation").val(),
                        status: 'Error'
                    });
                }
            }, function (response) {
                vm.recordScanLoading = false;
                $.bootstrapGrowl($("input#scanCode").val() + "->" + $("input#scanLocation").val() + " " +response.data.message, {ele: 'body', type: 'error'});
                vm.scanHistories.unshift({
                    scanCode: $("input#scanCode").val(),
                    location: $("input#scanLocation").val(),
                    status: 'Error'
                });
                $("input#scanCode").val('');
                if (vm.saveLocation) {
                    $("input#scanCode").focus();
                } else {
                    $("input#scanLocation").val('');
                    vm.scanLocation = null;
                    $("input#scanLocation").focus();
                }
            });
        }

        function recordWatchAssemblyScan() {
            if (!$("input#scanCode").val() || $("input#scanCode").val() == '') {
                $("input#scanCode").focus();
                return;
            }
            let uniqueId = Math.floor(Math.random() * 100000);
            let scanType = vm.scanType;

            vm.recordScanLoading = true;
            vm.scanHistories.unshift({
                uniqueId: uniqueId,
                scanType: scanType,
                scanCode: $("input#scanCode").val(),
                status: 'Pending'
            });

            let scanCode = vm.scanCode;
            
            $("input#scanCode").val('');
            $("input#scanCode").focus();
            serverGateway.ajax('recordWatchAssemblyScan', null, {code: scanCode, scanType}).then(function(response) {
                vm.recordScanLoading = false;
                vm.scanHistories = vm.scanHistories.map((history) => {
                    if (history.scanCode == scanCode && uniqueId == history.uniqueId) {
                        history.status = 'Success | Count: ' + response.data.data.count;
                        history.records = response.data.data.records;
                    }
                    return history
                })
                $.bootstrapGrowl($("input#scanCode").val() + " Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.recordScanLoading = false;
                $.bootstrapGrowl($("input#scanCode").val() + " " +response.data.message, {ele: 'body', type: 'error'});
                vm.scanHistories = vm.scanHistories.map((history) => {
                    if (history.scanCode == scanCode && uniqueId == history.uniqueId) {
                        history.status = 'Error';
                    }
                    return history
                })
            });
        }

        function showWatchAssemblyEdit(uniqueId) {
            let scanHistory = vm.scanHistories.find((scanHistory) => scanHistory.uniqueId == uniqueId);
            vm.showWatchEditLoading = true;
            serverGateway.ajax('listTransitionQueueItems', null, {code: scanHistory.scanCode}).then(function(response) {
                vm.showWatchEditLoading = false;
                vm.detail = {};
                vm.detail.uniqueId = uniqueId
                vm.detail.items = [];
                response.data.data.forEach((item) => {
                    let itemData = {
                        id: item.id,
                        preview: item.productionItem.orderItem.preview,
                        isActive: !(item.deletedAt || item.productionItem.deletedAt || item.productionItem.orderItem.deletedAt || item.productionItem.orderItem.delivery.deletedAt || item.productionItem.orderItem.delivery.order.deletedAt),
                        shouldScrap: !item.deletedAt && (item.productionItem.deletedAt || item.productionItem.orderItem.deletedAt || item.productionItem.orderItem.delivery.deletedAt || item.productionItem.orderItem.delivery.order.deletedAt),
                        watchAssemblyRecords: [],
                        inoutRecords: [],
                    };
                    item.productionItem.orderItem.productionItems.forEach((productionItem) => {
                        if (scanHistory.records[productionItem.id]) {
                            if (scanHistory.records[productionItem.id].watchAssemblyRecord) {
                                itemData.watchAssemblyRecords.push(scanHistory.records[productionItem.id].watchAssemblyRecord)
                            }
                            if (scanHistory.records[productionItem.id].inoutRecord) {
                                itemData.inoutRecords.push(scanHistory.records[productionItem.id].inoutRecord)
                            }
                        }
                    })
                    vm.detail.items.push(itemData);
                })
                $('#editWatchAssemblyModal').modal('show');
            }, function (response) {
                vm.showWatchEditLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function rejectWatchband(item) {
            if (!confirm('Confirm reject with printing quality issue and need reprint?')) {
                return;
            }
            vm.showWatchRejectLoading = true;
            serverGateway.ajax('rejectWatchband', {id: item.id}, {watchAssemblyRecords: item.watchAssemblyRecords.join(','), inoutRecords: item.inoutRecords.join(',')}).then(function(response) {
                vm.showWatchRejectLoading = false;
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
                showWatchAssemblyEdit(vm.detail.uniqueId);
            }, function (response) {
                vm.showWatchRejectLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function scrapWatchband(item) {
            if (!confirm('Confirm scrap watchband?')) {
                return;
            }
            vm.showWatchRejectLoading = true;
            serverGateway.ajax('scrapWatchband', {id: item.id}, {watchAssemblyRecords: item.watchAssemblyRecords.join(','), inoutRecords: item.inoutRecords.join(',')}).then(function(response) {
                vm.showWatchRejectLoading = false;
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
                showWatchAssemblyEdit(vm.detail.uniqueId);
            }, function (response) {
                vm.showWatchRejectLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function fixOrderBucket() {
            vm.fixBucketLoading = true;
            serverGateway.ajax('fixOrderBucket', null, {orderId: vm.fixBucketOrderID}).then(function(response) {
                vm.fixBucketLoading = false;
                $("input#fixBucketOrderID").val('');
            }, function (response) {
                vm.fixBucketLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function closeoutDHLShipment() {
            vm.closeoutLoading = true;
            serverGateway.ajax('closeoutDHLShipment', null).then(function(response) {
                vm.closeoutLoading = false;
                $.bootstrapGrowl('Closeout complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.closeoutLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function prepareGiftOrder() {
            vm.prepareGiftOrderLoading = true;
            serverGateway.ajax('prepareGiftOrder', null).then(function(response) {
                vm.prepareGiftOrderLoading = false;
                $.bootstrapGrowl('Prepare complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.prepareGiftOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showOOSPendingOrder(productId) {
            vm.oosLoading = true;
            $('#orderListDialog').modal('hide');
            vm.relatedOrders = null;
            serverGateway.ajax('getOOSPendingOrder', null, {'product_id': productId}).then(function(response) {
                vm.oosLoading = false;
                // $.bootstrapGrowl('Prepare complete.', {ele: 'body', type: 'success'});
                vm.relatedOrders = response.data;
                $('#orderListDialog').modal('show');
            }, function (response) {
                vm.oosLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showOOSHoldOrder(productId) {
            vm.oosLoading = true;
            $('#orderListDialog').modal('hide');
            vm.relatedOrders = null;
            serverGateway.ajax('getOOSHoldOrder', null, {'product_id': productId}).then(function(response) {
                vm.oosLoading = false;
                // $.bootstrapGrowl('Prepare complete.', {ele: 'body', type: 'success'});
                vm.relatedOrders = response.data;
                $('#orderListDialog').modal('show');
            }, function (response) {
                vm.oosLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showPendingOrder() {
            vm.pendingOrderLoading = true;
            $('#orderListDialog').modal('hide');
            vm.relatedOrders = null;
            serverGateway.ajax('getPendingOrder', null, null).then(function(response) {
                vm.pendingOrderLoading = false;
                // $.bootstrapGrowl('Prepare complete.', {ele: 'body', type: 'success'});
                vm.relatedOrders = response.data;
                $('#orderListDialog').modal('show');
            }, function (response) {
                vm.pendingOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showIdleOrder() {
            vm.idleOrderLoading = true;
            $('#orderListDialog').modal('hide');
            vm.relatedOrders = null;
            serverGateway.ajax('getIdleOrder', null, null).then(function(response) {
                vm.idleOrderLoading = false;
                // $.bootstrapGrowl('Prepare complete.', {ele: 'body', type: 'success'});
                vm.relatedOrders = response.data;
                $('#orderListDialog').modal('show');
            }, function (response) {
                vm.idleOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function showConvertOrder() {
            $('#orderConvertDialog').modal('show');
            vm.convertOrders = null;
        }

        function convertOrderIdFormat() {
            console.log(vm.convertOrders);
            if (vm.convertOrders.split("\n").length > 1) {
                vm.convertOrders = vm.convertOrders.replace(/\n/g, ",");
            } else {
                vm.convertOrders = vm.convertOrders.replace(/,/g, "\n");
            }
        }

        function restockReschedulePrinting() {
            vm.restockReschedulePrintingLoading = true;
            serverGateway.ajax('restockReschedulePrinting', null, {'skus' : vm.restockReschedulePrintingRawMaterial}).then(function(response) {
                vm.restockReschedulePrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'success'});
            }, function (response) {
                vm.restockReschedulePrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function outOfStockPrintingSpecialHandling() {
            vm.outOfStockPrintingSpecialHandlingLoading = true;
            serverGateway.ajax('outOfStockPrintingSpecialHandling', null, {'skus' : vm.outOfStockPrintingSpecialHandlingRawMaterial}).then(function(response) {
                vm.outOfStockPrintingSpecialHandlingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'success'});
            }, function (response) {
                vm.outOfStockPrintingSpecialHandlingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function removeInvalidPrintingItems() {
            vm.removeInvalidPrintingItemsLoading = true;
            serverGateway.ajax('removeInvalidPrintingItems', null).then(function(response) {
                vm.removeInvalidPrintingItemsLoading = false;
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.removeInvalidPrintingItemsLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sendInventoryReport() {
            vm.sendInventoryReportLoading = true;
            serverGateway.ajax('sendInventoryReport', null, {customFilter: vm.customFilter}).then(function(response) {
                vm.sendInventoryReportLoading = false;
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.sendInventoryReportLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sendFullWarehouseReport() {
            vm.sendFullWarehouseReportLoading = true;
            serverGateway.ajax('sendFullWarehouseReport', null).then(function(response) {
                vm.sendFullWarehouseReportLoading = false;
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.sendFullWarehouseReportLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function sendPresetWarehouseReport() {
            if (vm.sendPresetWarehouseReportLoading) return;
            vm.sendPresetWarehouseReportLoading = true;
            serverGateway.ajax('sendPresetWarehouseReport', null, {preset: vm.inventoryPreset, locationIds: $("#inventoryPresetLocations").val(), time: vm.inventoryPresetDate, 'createBackgroundOutputOnly': 'Y'}).then(function(response) {
                if (response.data && response.data.data && response.data.data.backgroundOutputId) {
                    console.log("Start generating report...");
                    let backgroundOutputId = response.data.data.backgroundOutputId;
                    let listenReportInterval = setInterval(() => {
                        console.log('listenting report...');
                        serverGateway.ajax('getBackgroundOutput', {id: backgroundOutputId}).then(function(response) {
                            if (response && response.data && response.data.data && response.data.data.status == 'completed') {
                                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
                                vm.sendPresetWarehouseReportLoading = false;
                                clearInterval(listenReportInterval);
                                window.location.href = "/background-outputs/{id}/download-file".replace('{id}', backgroundOutputId);
                            }
                            console.log(response);
                        }, function (response) {
                            clearInterval(listenReportInterval);
                            vm.sendPresetWarehouseReportLoading = false;
                            $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                        });
                    }, 5000);
                    serverGateway.ajax('sendPresetWarehouseReport', null, {preset: vm.inventoryPreset, locationIds: $("#inventoryPresetLocations").val(), time: vm.inventoryPresetDate, 'backgroundOutputId': response.data.data.backgroundOutputId}).then(function(response) {
                        console.log(response);
                    }, function (response) {
                        clearInterval(listenReportInterval);
                        vm.sendPresetWarehouseReportLoading = false;
                        $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                    });
                }
            }, function (response) {
                vm.sendPresetWarehouseReportLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });

            // serverGateway.ajax('sendPresetWarehouseReport', null, {preset: vm.inventoryPreset, locationIds: $("#inventoryPresetLocations").val(), time: vm.inventoryPresetDate}).then(function(response) {
            //     vm.sendPresetWarehouseReportLoading = false;
            //     $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            // }, function (response) {
            //     vm.sendPresetWarehouseReportLoading = false;
            //     $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            // });
        }

        function sendBOMReport() {
            vm.sendBOMReportLoading = true;
            serverGateway.ajax('sendBOMReport', null).then(function(response) {
                vm.sendBOMReportLoading = false;
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                vm.sendBOMReportLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function downloadNonPrintBatch() {
            window.location.href = "/function/non-print-batch/export";
        }

        function showYamatoPickup() {
            $('#yamatoPickupDialog').modal('show');
            vm.yamatoIds = null;
        }

        function generateManualPrint() {
            if (vm.generateManaulPrintLoading) {
                return;
            }
            vm.generateManaulPrintLoading = true;
            vm.manualPrintResult = '';
            serverGateway.ajax('generateManualPrint', null, {templateId: vm.templateId, stationId: vm.stationId, data: JSON.stringify(vm.slotsData)}).then(function(response) {
                if (!response.data.success) {
                    vm.generateManaulPrintLoading = false;
                    vm.slotsData = response.data.data.slotsData;
                } else {
                    vm.manualPrintResult = vm.manualPrintResult + "Mannal print result generated.\n";
                    var downloadPrintablePromises = [];
                    response.data.data.requestPreviewPrintables.forEach((item) => {
                        let downloadPrintablePreviewPromise = new Promise((resolve, reject) => {
                            vm.manualPrintResult = vm.manualPrintResult + "Downloading printable preview "+item.description+".\n";
                            serverGateway.ajax('forceRequestPrintablePreview', { id: item.id }, {regenerate: 'Y'}).then(function(response) {
                                vm.manualPrintResult = vm.manualPrintResult + "Finished downloading printable preview "+item.description+".\n";
                                resolve(response);
                            }, function(response) {
                                vm.manualPrintResult = vm.manualPrintResult + "Failed downloading printable preview "+item.description+".\n";
                                reject(response);
                            });
                        })
                        downloadPrintablePromises.push(downloadPrintablePreviewPromise)
                    })
                    response.data.data.requestPrintables.forEach((item) => {
                        let downloadPrintablePromise = new Promise((resolve, reject) => {
                            vm.manualPrintResult = vm.manualPrintResult + "Downloading printable "+item.description+".\n";
                            serverGateway.ajax('forceRequestPrintable', { id: item.id }, {regenerate: 'Y', force: 'Y'}).then(function(response) {
                                vm.manualPrintResult = vm.manualPrintResult + "Finished downloading printable "+item.description+".\n";
                                resolve(response);
                            }, function(response) {
                                vm.manualPrintResult = vm.manualPrintResult + "Failed downloading printable "+item.description+".\n";
                                reject(response);
                            });
                        })
                        downloadPrintablePromises.push(downloadPrintablePromise)
                    })
                    let orderId = response.data.data.orderId;
                    Promise.all(downloadPrintablePromises).then((results) => {
                        if (orderId) {
                            vm.generateManaulPrintLoading = true;
                            // vm.manualPrintResult = vm.manualPrintResult + "Cleaning up order.\n";
                            serverGateway.ajax('cancelOrder', {id: orderId}).then(function(response) {
                                vm.generateManaulPrintLoading = false;
                                // vm.manualPrintResult = vm.manualPrintResult + "Cleaning up order success.\n";
                                // $.bootstrapGrowl('Order Cancelled.', {ele: 'body', type: 'success'});
                            }, function (response) {
                                vm.generateManaulPrintLoading = false;
                                vm.manualPrintResult = vm.manualPrintResult + "Cleaning up order failed: "+response.data.message+".\n";
                            });
                        }
                        vm.manualPrintResult = vm.manualPrintResult + "Downloading printing batch.\n";
                        window.location.href = response.data.data.url;
                        $.bootstrapGrowl('Exporting...', {ele: 'body', type: 'success'});
                        vm.generateManaulPrintLoading = false;
                    }, function (results) {
                        let messages = "";
                        results.forEach((item) => {
                            messages = messages + item.data.message + "\n";
                        })
                        $.bootstrapGrowl(messages, {ele: 'body', type: 'error'});
                        vm.generateManaulPrintLoading = false;
                    })
                }
                
            }, function (response) {
                vm.generateManaulPrintLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function updateStockControlRemark(productId) {
            var remark = $('#stock-control-remark-' + productId).val();
            serverGateway.ajax('updateStockControlRemark', {'id': productId}, {'remark': remark}).then(function(response) {
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function schedulePendingOrder() {
            vm.schedulePendingOrderLoading = true;
            serverGateway.ajax('schedulePendingOrder', null, {'orderIds': vm.schedulePendingOrderIds}).then(function(response) {
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
                vm.schedulePendingOrderLoading = false;
            }, function (response) {
                vm.schedulePendingOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function createDemoOrders() {
            if (vm.demoOrderLoading) return;
            vm.demoOrderLoading = true;
            serverGateway.ajax('createDemoOrders').then(function(response) {
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
                vm.demoOrderLoading = false;
                window.location.reload();
            }, function (response) {
                vm.demoOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function deleteDemoOrders() {
            if (vm.demoOrderLoading) return;
            vm.demoOrderLoading = true;
            serverGateway.ajax('cancelDemoOrders').then(function(response) {
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
                vm.demoOrderLoading = false;
                window.location.reload();
            }, function (response) {
                vm.demoOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function updateSZPrintingQuota(existingQuota) {
            var quota = prompt('New Quota', existingQuota);
            if (quota !== null) {
                serverGateway.ajax('updateSZPrintingQuota', null, {quota: quota}).then(function(response) {
                    $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
                    window.location.reload();
                }, function (response) {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            }
            
            return false;
        }

        function demoAssignPrintBotTask() {            
            serverGateway.ajax('demoAssignPrintBotTask', {id: 1}).then(function(response) {
                vm.taskId = response.data.data.id;
                vm.demoListPrintBotTask();
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            return false;
        }

        function demoPrintBotTaskStartPrinting() {            
            serverGateway.ajax('demoPrintBotTaskStartPrinting', {id: vm.taskId}).then(function(response) {
                console.log(response.data.data);
                vm.demoListPrintBotTask();
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            return false;
        }

        function demoPrintBotTaskFinishPrinting() {            
            serverGateway.ajax('demoPrintBotTaskFinishPrinting', {id: vm.taskId}).then(function(response) {
                console.log(response.data.data);
                vm.demoListPrintBotTask();
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            return false;
        }

        function demoPrintBotTaskComplete() {            
            serverGateway.ajax('demoPrintBotTaskComplete', {id: vm.taskId}).then(function(response) {
                console.log(response.data.data);
                vm.demoListPrintBotTask();
                $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            return false;
        }

        function demoListPrintBotTask() {            
            serverGateway.ajax('demoListPrintBotTask', {id: 1}).then(function(response) {
                console.log(response.data.data);
                if (response.data.data.length) {
                    vm.taskId = response.data.data[0].id;
                } else {
                    vm.taskId = null;
                }
                
                // $.bootstrapGrowl('complete.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            
            return false;
        }

        function exportBacklog(sku) {   
            vm.exportBacklogLoading = true;
            window.location.href= "/function/backlog/export?sku="  + encodeURIComponent(sku);
            
            return false;
        }

        function saveRawMaterialZone(rawMaterialId, event) {
            let zone = $(event.currentTarget).parents("td").find(".zone-input").val();
            serverGateway.ajax('updateRawMaterialWIPZone', {id: rawMaterialId}, {code: zone, locationId: config.locationId}).then(function(response) {
                $.bootstrapGrowl('updated.', {ele: 'body', type: 'success'});
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
            return false;
        }

        function scheduleNonPrintTask() {
            serverGateway.ajax('scheduleNonPrintTask').then(function(response) {
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function clearShippingLabel(refId) {
            serverGateway.ajax('clearShippingLabel', null, {refId: refId}).then(function(response) {
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function prefillScanLocation(location) {
            vm.scanLocation = location;
        }

        function scheduleSZPrinting() {
            vm.scheduleSZPrintingLoading = true;
            serverGateway.ajax('scheduleSZPrinting').then(function(response) {
                vm.scheduleSZPrintingLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.scheduleSZPrintingLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function packStationTestPrint() {
            vm.testPrintLoading = true;
            serverGateway.ajax('packStationTestPrint', {id: vm.testPackStation}).then(function(response) {
                vm.testPrintLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.testPrintLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function scheduleManaulOrder(orderId) {
            vm.scheduleManaulOrderLoading = true;
            serverGateway.ajax('scheduleManaulOrder', null, {orderId: vm.manualOrderId}).then(function(response) {
                vm.scheduleManaulOrderLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.scheduleManaulOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function cleanupManaulOrder(orderId) {
            vm.scheduleManaulOrderLoading = true;
            serverGateway.ajax('cleanupManaulOrder', null, {orderId: vm.cleanupManualOrderId}).then(function(response) {
                vm.scheduleManaulOrderLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.scheduleManaulOrderLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function goOverview(){
            var params = {};
            window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
                params[key] = value;
            });
            var href = '/products/checklist-overview?';
            if(typeof params['location'] != 'undefined'){
                href = href+'location='+params['location'];
            }
            window.location = href;
        }

        function showMergeSlotCreateDialog() {
            $('#mergeSlotCreateDialog').modal('show');
        }

        function createMergeGroup() {
            serverGateway.ajax('createMergeGroup', null, {name: vm.mergeGroupName, row: vm.mergeGroupRowCount, col: vm.mergeGroupColCount}).then(function(response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function saveMinPrintingBatchItemForHK(){
            serverGateway.ajax('saveMinPrintingBatchItemForHK', null, {value: vm.minPrintingBatchItemForHK}).then(function(response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function saveMinCurrentPrintingBatchItemForHK(){
            serverGateway.ajax('saveMinCurrentPrintingBatchItemForHK', null, {value: vm.minCurrentPrintingBatchItemForHK}).then(function(response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl("Complete", {ele: 'body', type: 'success'});
                window.location.reload();
            }, function (response) {
                vm.createMergeGroupLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function openSpecialHandlingArtworkModal(){
            //specialHandlingArtwork
            $('#specialHandlingArtworkModal').modal('show');
            fetchSpecialHandlingArtwork()
        }

        function fetchSpecialHandlingArtwork(){
            serverGateway.ajax('getSpecialHandlingArtworkList').then(function(response){
                if(response.data.status == 'OK'){
                    vm.specialHandlingArtworkList = response.data.data
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function addSpecialHandlingArtwork(){
            if(!vm.specialHandlingArtwork){
                $.bootstrapGrowl('Entry is Empty', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('addSpecialHandlingArtworkList',null,{artwork_id:vm.specialHandlingArtwork}).then(function(response){
                vm.specialHandlingArtwork = '';
                fetchSpecialHandlingArtwork();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function deleteSpecialHandlingArtwork(id){
            if(!id){
                $.bootstrapGrowl('ID is Empty', {ele: 'body', type: 'error'});
                return;
            }
            if(!confirm('Are you sure?')) return;
            serverGateway.ajax('deleteSpecialHandlingArtworkList',{id:id}).then(function(response){
                fetchSpecialHandlingArtwork();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function regeneratePrintables(id = null){
            console.log(id);
            if(!confirm('Are you sure? This will regenerate all the printables below, and it will take a while')) return;
            serverGateway.ajax('regeneratePrintables',{id:id}).then(function(response){
                if(response.data.status == 'OK'){
                    if(response.data.msg != ''){
                        $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'warning'});
                        $('#specialHandlingArtworkModal').modal('hide');
                    } else {
                        $.bootstrapGrowl('Regenerate requested. Please wait a while for the regeneration', {ele: 'body', type: 'success'});
                    }
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function applyLoadBalance(){
            if(!confirm('Are you sure? This will affect scheduled batches. and It will take a while')) return;
            vm.loadBalanceLoading = true;
            serverGateway.ajax('applyLoadBalance',null, {stationNames:vm.loadBalanceStations}).then(function(response){
                vm.loadBalanceLoading = false;
                $.bootstrapGrowl('Load balance done.', {ele: 'body', type: 'success'});
            },function(response){
                vm.loadBalanceLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function generateSerialNumber(){
            if(!confirm('Are you sure? This will regenerate new Serial Number')) return;
            vm.generateSerialNumberLoading = true;
            serverGateway.ajax('generateSerialNumber',null, {batch_create_count:vm.serialNumberBatchCreateCount}).then(function(response){
                vm.generateSerialNumberLoading = false;
                $.bootstrapGrowl('Generate Serial Number done.', {ele: 'body', type: 'success'});
            },function(response){
                vm.generateSerialNumberLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function downloadSerialNumber(){
            window.location.href = "/function/download-serial-number?batch_create_date="+moment(vm.serialNumberBatchCreateDate).format('YYYY-MM-DD');
        }

        function downloadPop(){
            if(!vm.popRequestDate && !vm.popRequestOrderId){
                $.bootstrapGrowl('Date/Order Id cannot be empty', {ele: 'body', type: 'error'});
            }
            vm.generatePopFileLoading = true;
            serverGateway.ajax('downloadPop',null,{date: vm.popRequestDate, orderid: vm.popRequestOrderId}).then(function(response){
                if(response.data){
                    console.log(response.data);
                    let filename = response.data.filename;
                    window.location.href = 'getPopFile/' + filename;
                }
                vm.generatePopFileLoading = false;
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.generatePopFileLoading = false;
            });
        }

        function saveBypassOrderList(){
            if(!vm.bypassOrderList){
                $.bootstrapGrowl('Bypass Order# List cannot be empty', {ele: 'body', type: 'error'});
            }
            vm.savingBypassOrderList = true;
            serverGateway.ajax('saveBypassOrderList',null,{orderlist: vm.bypassOrderList}).then(function(response){
                $.bootstrapGrowl('DONE!', {ele: 'body', type: 'success'});
                vm.generatePopFileLoading = false;
                window.location.reload();
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.generatePopFileLoading = false;
            });
        }

        function requestInventoryForecastReport(){
            if(vm.inventoryForecastRequested){
                $.bootstrapGrowl('Report already requested', {ele: 'body', type: 'error'});
                return;
            }
            vm.inventoryForecastRequested = true;
            serverGateway.ajax('requestInventoryForecastReport').then(function(response){
                $.bootstrapGrowl('Report requested, report will be sent by email, may take a while to generate', {ele: 'body', type: 'success'});
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.inventoryForecastRequested = false;
            });
        }

        function setPersistenceVar(key) {
            serverGateway.ajax('setPersistenceVar', null, {key: key, value: vm.persistentVarMap[key]}).then(function(response){
                $.bootstrapGrowl('Done', {ele: 'body', type: 'success'});
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});

            });
        }

        function submitBulkSwitchOrderLocation() {
            if (vm.switchOrderLocationLoading) {
                return false;
            }
            vm.switchOrderLocationLoading = true;
            serverGateway.ajax('submitBulkSwitchOrderLocation', null, {location: vm.bulkSwitchLocationId, orderIds: vm.bulkSwitchOrderIds}).then(function(response){
                vm.switchOrderLocationLoading = false;
                $.bootstrapGrowl('Done. Request sent through email.', {ele: 'body', type: 'success'});
            }, function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.switchOrderLocationLoading = false;
            });
            return false;
        }

        function resetRoboticAllTote() {
            if (vm.resetRoboticAllToteLoading) return;
            vm.resetRoboticAllToteLoading = true;
            serverGateway.ajax('resetRoboticAllTote').then(function(response){
                vm.resetRoboticAllToteLoading = false;
                $.bootstrapGrowl('Success.', {ele: 'body', type: 'success'});
            },function(response){
                vm.resetRoboticAllToteLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function initStationSelect2(){
            $(document).ready(function() {
                $('.station-select2').select2();
            });
        }

        function showCreateModal() {
            PageViewModel.openModal('#modal-create');
        }
      
        function showSpecialHandlingArtwork(id) {
            serverGateway.ajax('showSpecialHandlingArtwork',{id: id}).then(function(response){
                vm.detail = response.data.data;
                PageViewModel.openModal('#modal-show');
                window.history.pushState(null, null, "/function/special-handling-artwork/" + id);
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }
      
        function createSpecialHandlingArtwork() {
            if (!vm.detail.content) {
              $.bootstrapGrowl('Please input content', {ele: 'body', type: 'error'});
              return;
            }

            serverGateway.ajax('createSpecialHandlingArtwork', {}, vm.detail).then(function (response) {
                $.bootstrapGrowl('Special Handling Artwork created', {ele: 'body', type: 'success'});
                window.location.reload();
            },function (response) {
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function updateSpecialHandlingArtwork() {
            if (!vm.detail.content) {
                $.bootstrapGrowl('Please input content', {ele: 'body', type: 'error'});
                return;
            }
            serverGateway.ajax('updateSpecialHandlingArtwork',{id: vm.detail.id}, vm.detail).then(function(response){
                $.bootstrapGrowl('Special Handling Artwork was updated', {ele: 'body', type: 'success'});
                window.location = '/function/special-handling-artwork';
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function removeSpecialHandlingArtwork(id) {
            serverGateway.ajax('removeSpecialHandlingArtwork',{id: id}).then(function(response){
                $.bootstrapGrowl('Special Handling Artwork was deleted', {ele: 'body', type: 'success'});
                window.location = '/function/special-handling-artwork';
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            })
        }

        function submitScannerInput() {
            if (vm.scannerLoading) return false
            vm.scannerLoading = true;
            if (vm.scanner.mode == 'go_printing_transit') {
                let data = JSON.parse(vm.scanner.input)
                if (!data) {
                    $.bootstrapGrowl('cannot parse data', {ele: 'body', type: 'error'});
                    return
                }
                if (!data.id) {
                    $.bootstrapGrowl('cannot parse data id', {ele: 'body', type: 'error'});
                    return
                }
                let transitId = data.id
                window.open("/inventory/printing-stock-transit/" + transitId, "_blank");
            } else if (vm.scanner.mode == 'fulfil_transit') {
                let data = JSON.parse(vm.scanner.input)
                if (!data) {
                    $.bootstrapGrowl('cannot parse data', {ele: 'body', type: 'error'});
                    return
                }
                if (!data.id) {
                    $.bootstrapGrowl('cannot parse data id', {ele: 'body', type: 'error'});
                    return
                }
                let transitId = data.id

                
                serverGateway.ajax('fulfill-transit-request', {id: transitId}, {autofill: true}).then(function(response) {
                    vm.scannerLoading = false;
                    vm.scanner.input = null;
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                }, function (response) {
                    vm.scannerLoading = false;
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                });
            } else {
                vm.scannerLoading = false;
            }
            
        }

        function searchUnshupProductionItemSku() {
            if (vm.changeUnshipProductionItemSkuLoading) return false
            vm.changeUnshipProductionItemSkuLoading = true;

            serverGateway.ajax('searchUnshupProductionItemSku', {}, {fromSku: vm.fromSku, toSku: vm.toSku}).then(function(response) {
                vm.changeUnshipProductionItemSkuLoading = false;
                vm.detail = response.data.data;

                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            }, function (response) {
                vm.changeUnshipProductionItemSkuLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
        function changeUnshupProductionItemSku() {
            if (vm.changeUnshipProductionItemSkuLoading) return false
            vm.changeUnshipProductionItemSkuLoading = true;

            serverGateway.ajax('changeUnshupProductionItemSku', {}, {
                action: 'execute',
                fromSku: vm.fromSku,
                toSku: vm.toSku, 
                orderIds: vm.detail.orderRefIds,
                deliveryIds: vm.detail.deliveryIds
            }).then(function(response) {
                vm.changeUnshipProductionItemSkuLoading = false;
                
                if (response.data.status === true) {
                    $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                } else {
                    $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                }

            }, function (response) {
                vm.changeUnshipProductionItemSkuLoading = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function migrateBulkOrder(){
            serverGateway.ajax('migrateBulkOrder',null,{orderId: vm.migrateBulkOrderId}).then(function(response){
                vm.migrateBulkOrderId = null;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.needCheckRequest = false;
            });
        }

        function bulkOrderHidePrinting(){
            serverGateway.ajax('bulkOrderHidePrinting',null,{orderId: vm.bulkOrderIdHidePrinting}).then(function(response){
                vm.bulkOrderIdHidePrinting = null;
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.needCheckRequest = false;
            });
        }

        function bulkOrderMarkShipped(orderId){
            serverGateway.ajax('bulkOrderMarkShipped',{id: orderId}).then(function(response){
                $.bootstrapGrowl("Success", {ele: 'body', type: 'success'});
                $('#order-'+orderId).html('completed');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    angular
        .module('specialFunction')
        .controller('RequestRfidController', RequestRfidController);

    RequestRfidController.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval','FileUploader'];

    function RequestRfidController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval, FileUploader){
        $scope.requestFileUploader = new FileUploader({
            autoUpload: true,
            removeAfterUpload: true,
            headers: {
                Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
            },
            onCompleteItem: function(){
                $("input[name=request_file]").val('');
            },
            onSuccessItem: function (item,response) {
                $scope.labelRequest = [];
                let type = $('#import_type').val() || 'none';
                //console.log(response.data);
                for(let key in response.data){
                    let row = response.data[key];
                    $scope.labelRequest.push({content: row.content, qr_content: row.qr_content, count: row.count, lookup: row.lookup, content_2: row.content_2,type: type,valid: true})
                }

            }
        });

        var serverGateway = null;
        var vm = this;

        vm.rows = [];
        vm.sorts = [];
        vm.columns = config.columns;
        vm.setFilter   = PageViewModel.setFilter;
        vm.clearFilter = PageViewModel.clearFilter;
        vm.sortChange  = PageViewModel.sortChange;
        vm.isLoading   = PageViewModel._isLoading;
        vm.selectedType = '';

        vm.updateTable = updateTable;
        vm.openRequestModal = openRequestModal;
        vm.addLabelRequest = addLabelRequest;
        vm.removeRequestRow = removeRequestRow;
        vm.requestLabel = requestLabel;
        vm.submitLabel = submitLabel;
        vm.updateUploaderUrl = updateUploaderUrl;
        vm.needCheckRequest = false;
        vm.importType = '';

        initialize();

        function initialize(){

            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'validateLabelContent': { path: 'function/validateLabelContent', method: 'POST'},
                    'submitLabelContent': { path: 'function/submitLabelContent', method: 'POST'},
                }
            });

            $scope.requestFileUploader.url = '/api/function/processImportFile?type=none';

            vm.gridDataSource = new GridDataSource({
                resoucesUrl  : config.dataUrl,
                gateway      : serverGateway,
                perPage: 99
            });

            var fields = [];
            for(var i, c = config.columns.length; i < c; i++){
                fields.push(config.columns[i].field);
            }
            vm.gridDataSource.setColumns(fields);

            PageViewModel.setGridDataSource(vm.gridDataSource);
            $scope.$watch(function(){
                return PageViewModel.rows;
            }, function(rows) {
                vm.rows = rows;
            }), true;

            $timeout(function(){PageViewModel.update();}, 500);

            $interval(function () {
                PageViewModel.update();
            }, 5000);

        }

        function updateUploaderUrl(){
            let type = $('#import_type').val();
            $scope.requestFileUploader.url = '/api/function/processImportFile?type='+type;
            console.log($scope.requestFileUploader.url);
        }

        function updateTable(){
            console.log(vm.selectedType);

            vm.gridDataSource.setRules(vm.selectedType);
            PageViewModel.update();
        }

        function openRequestModal(){
            console.log('openRequestModal');
            $('#request-rfid-modal').modal('show');
            $scope.labelRequest = [];
            vm.needCheckRequest = false;
        }

        function addLabelRequest(){
            let content = $('#request_content_input').val();
            let content_2 = $('#request_content2_input').val();
            let qrcode_content = $('#request_qrcode_input').val();
            let label_count = $('#request_label_count').val();
            let lookup = $('#request_lookup_index').val();
            let type = $('#request_type').val();
            vm.needCheckRequest = false;

            if(label_count < 0){
                $.bootstrapGrowl("Please enter a valid label count", {ele: 'body', type: 'error'});
                return;
            }

            $scope.labelRequest.push({content: content, content_2: content_2,qr_content: qrcode_content, count: label_count, lookup: lookup,type: type,valid: true});
            $('#request_lookup_index').val('');
            $('#request_content_input').val('');
            $('#request_qrcode_input').val('');
        }

        function removeRequestRow(index){
            vm.needCheckRequest = false;
            $scope.labelRequest.splice(index,1);
        }

        function requestLabel(){
            serverGateway.ajax('validateLabelContent',null,{content: $scope.labelRequest}).then(function(response){
                console.log(response.data);
                if(response.data.status == 'OK'){
                    vm.needCheckRequest = false;
                    submitLabel();
                } else {
                    response.data.result.forEach(function(item,i){
                        $scope.labelRequest[i].valid = item;
                    });
                    vm.needCheckRequest = true;
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.needCheckRequest = false;
            });
        }

        function submitLabel(){
            if(vm.needCheckRequest){
                if(!confirm('You have unmapped labels. Are you sure you want to generate the excel file anyway?')) return;
            }
            console.log('Generate Excel');

            serverGateway.ajax('submitLabelContent',null,{content: $scope.labelRequest}).then(function(response){
                if(response.data.filename){
                    window.location.href = 'getRFIDExcel/' + response.data.filename;
                }
                $('#request-rfid-modal').modal('hide');
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }

    angular
        .module('specialFunction')
        .controller('PrintingStatusController', PrintingStatusController);

    PrintingStatusController.$inject = ['$scope','GridDataSource','ServerGateway','$q','PageViewModel','$timeout'];
    function PrintingStatusController($scope, GridDataSource, ServerGateway, $q, PageViewModel, $timeout){

        var serverGateway = null;
        var vm = this;

        vm.requestPrintingStock = requestPrintingStock;

        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'createPrintingStockTransit': { path: 'printing/{id}/createPrintingStockTransit', method: 'POST'},
                }
            });
        }

        function requestPrintingStock(id){
            console.log('call requestPrintingStock');
            var requestCount = prompt('Please enter the no. of requests', 0);
            console.log(requestCount)

            serverGateway.ajax('createPrintingStockTransit',{id: id}, {count: requestCount}).then(function(response){
                if(response){
                    $.bootstrapGrowl('DONE!', {ele: 'body', type: 'success'});
                }
            },function(repsonse){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }


    }

    angular
        .module('specialFunction')
        .controller('AssociateSerialNumberController', AssociateSerialNumberController);

    AssociateSerialNumberController.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval','FileUploader'];

    function AssociateSerialNumberController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval, FileUploader){

        var serverGateway = null;
        var vm = this;
        vm.isLoading   = PageViewModel._isLoading;
        
        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'associateSerialNumber': { path: 'function/associate-serial-number', method: 'POST'}
                }
            });

            vm.sn = '';
            vm.ctf = '';
            vm.upc = '';
            vm.stockOutLocationId = config.defaultLocationId;
            vm.submitSerialNumber = submitSerialNumber;
            vm.isSubmiting = false;
            vm.uploadSerialNumber = uploadSerialNumber;
            vm.onChangeSerialNumber = onChangeSerialNumber;
            vm.bulkResults = [];

            vm.serialNumberFileUploader = new FileUploader({
                autoUpload: true,
                removeAfterUpload: true,
                headers: {
                    Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
                },
                url: '/api/function/bulk-associate-serial-number',
                onBeforeUploadItem: function(){
                    vm.isSubmiting = true;
                    vm.bulkResults = [];
                    vm.getPreviews = [];
                    vm.product = null;
                },
                onCompleteItem: function(){
                    vm.isSubmiting = false;
                    $('#serial_number_file').val('');
                },
                onSuccessItem: function (item,response) {
                    if(response.status == 'OK'){
                        if(response.failed == 0){
                            $.bootstrapGrowl('All Success!', {ele: 'body', type: 'success'});
                        }
                        else{
                            $.bootstrapGrowl(`Associated with ${response.failed} fails`, {ele: 'body', type: 'warning'});
                            window.location.assign(`/function/download-associate-serial-number-report?csv=${response.csv}`);
                        }
                        vm.bulkResults = response.previewResults;
                        vm.getPreviews = [];
                        for(let bulkResult in vm.bulkResults){
                            bulkResult = vm.bulkResults[bulkResult];
                            if(!bulkResult.preview){
                                vm.getPreviews[bulkResult.previewId] = setInterval(function(){ 
                                    $.get(`/uploadFile/${bulkResult.previewId}`, ()=>{
                                        bulkResult.loadedPreview = true;
                                        clearInterval(vm.getPreviews[bulkResult.previewId]);
                                    });
                                }, 2000);
                            }
                        }
                    }
                    else{
                        $.bootstrapGrowl(response.msg, {ele: 'body', type: 'error'});
                    }
                },
                onErrorItem: function (item,response) {
                    $.bootstrapGrowl(response.message, {ele: 'body', type: 'error'});
                }
            });
        }

        function submitSerialNumber(){
            if(!vm.ctf && !vm.upc){
                $.bootstrapGrowl('Please fill in CTF or UPC', {ele: 'body', type: 'error'});
                return;
            }
            if(!vm.sn){
                $.bootstrapGrowl('Please fill in Serial Number', {ele: 'body', type: 'error'});
                return;
            }
            if(vm.isSubmiting){
                return;
            }
            if(!confirm('Confirm update?')){
                return;
            }
            vm.isSubmiting = true;
            vm.bulkResults = [];
            vm.getPreviews = [];
            vm.product = null;
            serverGateway.ajax('associateSerialNumber', null, {sn: vm.sn, ctf: vm.ctf, upc: vm.upc, locationId: vm.stockOutLocationId}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Associate Success!', {ele: 'body', type: 'success'});
                    vm.product = response.data.product;
                    if(!vm.product.preview && vm.product.previewId){
                        var getPreview = setInterval(function(){ 
                            $.get(`/uploadFile/${vm.product.previewId}`, ()=>{
                                $('#preview_container').html(`<img class="w-100" src="/uploadFile/${vm.product.previewId}" />`);
                                clearInterval(getPreview);
                            });
                        }, 2000);
                    }
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.isSubmiting = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isSubmiting = false;
            });
        }

        function uploadSerialNumber(){
            $('#serial_number_file').click();
        }

        function onChangeSerialNumber(){
            const regex = /casetify\.com\/auth\/([\w\-]*)/igm;
            let regexresult = regex.exec(vm.sn);
            if (regexresult && regexresult[1]) {
                vm.sn = regexresult[1];
            }
        }
    }

    angular
        .module('specialFunction')
        .controller('WarehouseQrcodeGeneratorController', WarehouseQrcodeGeneratorController);

    WarehouseQrcodeGeneratorController.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval','FileUploader'];

    function WarehouseQrcodeGeneratorController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval, FileUploader){

        var serverGateway = null;
        var vm = this;
        vm.sku = '';
        vm.po = '';
        vm.qty = '';
        vm.carton = '';
        vm.total_qty = '';
        vm.datecode = '';
        vm.country = 'China';
        vm.isLoading = PageViewModel._isLoading;
        vm.submit = submit;
        vm.onChangeQty = onChangeQty;
        vm.onChangeCarton = onChangeCarton;
        vm.onChangeTotalQty = onChangeTotalQty;
        vm.uploadWarehouseQrcode = uploadWarehouseQrcode;
        vm.calculateCartonQty = calculateCartonQty;
        
        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'generateWarehouseQrcode': { path: 'function/generate-warehouse-qrcode', method: 'POST'}
                }
            });

            vm.isSubmiting = false;

            vm.warehouseQrcodeFileUploader = new FileUploader({
                autoUpload: true,
                removeAfterUpload: true,
                headers: {
                    Authorization: 'Basic ' + localStorage.getItem('api-auth-token'),
                },
                url: '/api/function/bulk-generate-warehouse-qrcode',
                onBeforeUploadItem: function(){
                    vm.isSubmiting = true;
                },
                onCompleteItem: function(){
                    vm.isSubmiting = false;
                    $('#warehouse_qrcode_file').val('');
                },
                onSuccessItem: function (item,response) {
                    if(response.status == 'OK'){
                        $.bootstrapGrowl('Bulk upload all success! Start download now', {ele: 'body', type: 'success'});
                        downloadWarehouseQrcode(response.filename);
                    }
                    else{
                        $.bootstrapGrowl(response.msg, {ele: 'body', type: 'error'});
                    }
                },
                onErrorItem: function (item,response) {
                    $.bootstrapGrowl(response.message, {ele: 'body', type: 'error'});
                }
            });
        }

        function submit(){
            vm.isSubmiting = true;
            serverGateway.ajax('generateWarehouseQrcode', null, {sku: vm.sku, po: vm.po, qty: vm.qty, carton: vm.carton, datecode: vm.datecode, country: vm.country, total_qty: vm.total_qty}).then(function(response){
                if(response.data.status == 'OK'){
                    $.bootstrapGrowl('Success! Start download now', {ele: 'body', type: 'success'});
                    downloadWarehouseQrcode(response.data.filename);
                } else {
                    $.bootstrapGrowl(response.data.msg, {ele: 'body', type: 'error'});
                }
                vm.isSubmiting = false;
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
                vm.isSubmiting = false;
            });
        }

        function onChangeQty(){
            vm.qty = parseInt(vm.qty);
        }

        function onChangeCarton(){
            vm.carton = parseInt(vm.carton);
        }

        function onChangeTotalQty(){
            vm.total_qty = parseInt(vm.total_qty);
        }

        function downloadWarehouseQrcode(filename){
            window.location.assign(`/download-warehouse-qrcode?filename=${filename}`);
        }

        function uploadWarehouseQrcode(){
            $('#warehouse_qrcode_file').click();
        }

        function calculateCartonQty(){
            if(!vm.qty){
                return 0;
            }
            if(!vm.total_qty){
                return 1;
            }
            return Math.ceil(vm.total_qty / vm.qty);
        }
    }

    angular
        .module('specialFunction')
        .controller('ArtworkClearCacheController', ArtworkClearCacheController);

    ArtworkClearCacheController.$inject = ['$scope','GridDataSource','ServerGateway','$q','config','PageViewModel','$timeout','$interval'];

    function ArtworkClearCacheController($scope, GridDataSource, ServerGateway, $q, config, PageViewModel, $timeout, $interval){

        var serverGateway = null;
        var vm = this;
        vm.isLoading   = PageViewModel._isLoading;
        
        initialize();

        function initialize(){
            serverGateway = new ServerGateway({
                baseUrl: '/api',
                endPoints: {
                    'artworkClearCacheData': { path: 'function/artwork-clear-cache-data', method: 'GET'},
                    'artworkClearCache': { path: 'function/artwork-clear-cache', method: 'POST'},
                }
            });

            vm.artworkIds = '';
            vm.processings = [];
            vm.histories = [];
            vm.onClickClear = onClickClear;
            vm.isSubmiting = false;

            updateList();

            $interval(function () {
                updateList();
            }, 5000);
        }

        function updateList(){
            serverGateway.ajax('artworkClearCacheData').then(function(response){
                if('processings' in response.data){
                    vm.processings = response.data.processings.map(processing => {
                        return {
                            ...processing,
                            process: processing.total ? processing.current/processing.total * 100 : 0
                        }
                    });
                }
                if('histories' in response.data){
                    vm.histories = response.data.histories.map(history => {
                        return {
                            ...history,
                            process: history.total ? history.current/history.total * 100 : 0
                        }
                    });
                }
            },function(response){
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }

        function onClickClear(){
            if(vm.isSubmiting){
                return;
            }
            if(!vm.artworkIds){
                $.bootstrapGrowl('Please fill artwork ids', {ele: 'body', type: 'error'});
                return;
            }
            vm.isSubmiting = true;
            serverGateway.ajax('artworkClearCache', null, {artworkIds: vm.artworkIds}).then(function(response){
                updateList();
                vm.isSubmiting = false;
                $.bootstrapGrowl('Done!', {ele: 'body', type: 'success'});
            },function(response){
                vm.isSubmiting = false;
                $.bootstrapGrowl(response.data.message, {ele: 'body', type: 'error'});
            });
        }
    }
})();
